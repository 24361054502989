.contact-container{
    width: 100%;
    height: auto;
}
.contact-div{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    margin: 0 auto;
}
.contac-div-form{
    background-image: url("../../img-components/fondo-contac.png");
    background-size: cover;
    background-position-x: 70%;
    grid-area: a;
    position: relative;
    padding-bottom: 100px;
}
.capa-oscura-contact{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.279);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.form-contac{
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    height: 100%;
    z-index: 200 !important;
    position: relative;
}
.contact-title{
    font-size: 40px;
    text-align: center;
    color: white;
}

.input-name, .input-email, .input-msg, .input-phone{
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
}
.input-name input{
    height: 50px;
    border-radius: 10px;
    width: 80%;
    border: none;
    font-size: 20px;
    padding-left: 15px;
    background-color: rgb(239, 239, 239);
    color: black;
}

.input-phone input{
    height: 50px;
    border-radius: 10px;
    width: 80%;
    border: none;
    font-size: 20px;
    padding-left: 15px;
    background-color: rgb(239, 239, 239);
    color: black;
}

.input-email input{
    height: 50px;
    border-radius: 10px;
    width: 80%;
    border: none;
    font-size: 20px;
    padding-left: 15px;
    background-color: rgb(239, 239, 239);
    color: black;
}

.input-msg textarea{
    height: 120px;
    border-radius: 10px;
    width: 80%;
    border: none;
    font-size: 20px;
    padding-left: 15px;
    background-color: rgb(239, 239, 239);
    padding-top: 15px;
    color: black;
}

.button-enviar{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.button-enviar button{
    width: 90%;
    height: 50px;
    font-size: 20px;
    color: black;
    border-radius: 10px;
    background-color: rgb(147, 211, 244);
    border: none;
    text-align: center;
    font-weight: bold;
}

.button-enviar button:hover{
    cursor: pointer;
}


.address{
    width: 100%;
    margin: 0 auto;
    grid-area: b;
    background-color: #224052;
    padding-top: 50px;
}

.container-adrees{
    grid-area: a;
    height: 55%;
}
.logo-empresa{
    width: 80%;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 20px;
}
.logo-empresa img{
    width: 150px;
}
.container-map{
    margin: 0 auto;
    height: 30%;
    width: 90%;
}
.info-hi{
    margin: 0 auto;
    width: 90%;
}
.higrid1{
    display: grid;
    grid-template-columns: 10% 90%;
    grid-area: "a b";
    width: 100%;
    margin: 0 auto;
}
.grid-a{
    grid-area: "a";
    width: 100%;
}
.grid-a svg{
    font-size: 50px;
    color: white;
}
.grid-b{
    grid-area: "b";
    width: 100%;
    padding-left: 10px;
    margin-bottom: 10px;
}
.p1{
    font-size: 18px;
    color: white;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
}
.p2{
    font-size: 18px;
    color: white;
    margin-top: 0px;
}
.input-captcha{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
}
.input-captcha div{
    margin: 0 auto;
    padding-bottom: 5px;
}


@media only screen and (max-width: 480px) {

    .contact-div{
        grid-template-areas: 
        "a a"
        "b b";
        overflow: hidden;
    }
    
    .address{
        padding-top: 0px;
    }
    .container-map{
       margin-top: 50px;
    }
}