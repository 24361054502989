.privacidad-container{
    width: 80%;
    margin: 0 auto;
    color: black;
    position: relative;
    z-index: 200 !important;
    filter: brightness(100%);
}
.privacidad-container h2{
    font-size: 20px;
    font-weight: bold;
}
.privacidad-container p{
    text-align: justify;
    font-size: 18px;
}
.privacidad-container li{
    font-size: 18px;
    text-align: justify;
    padding-top: 10px;
}
.privacidad-container h3{
    text-align: left;
}

@media only screen and (max-width: 480px) {
    .privacidad-container{
        width: 90%;
    }
}