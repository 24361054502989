.slide_container{
    width: 100%;
    height: 400px;
    background-image: url("../../img-components/slide1-aws.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 35%;
}
.slid-grid{
    width: 80%;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: "a b";
    margin: 0 auto;
    padding-top: 5%;
}

.slide-text{
    color: white;
    font-size: 30px;
    grid-area: a;
}
.medalla-aws{
    grid-area: b;
    margin-top: 50px;
    text-align: left;
    padding-left: 50px;
}
.medalla-aws img{
    width: 300px;
    margin-top: 20px;
}
.medalla-aws img:hover{
    cursor: pointer;
}


@media only screen and (max-width: 720px) {
    .slid-grid{
        grid-template-areas: 
        "a a"
        "b b";
    }
    .medalla-aws{
        display: none;
    }

    .slide-text{
        font-size: 25px;
        padding-top: 50px;
        text-align: center;
    }
    .slide_container{
        height: auto;
    }
}

@media only screen and (max-width: 480px) {
    .slide-text{
        font-size: 20px;
    }
}