.saas-container{
    width: 100%;
    height: auto;
    
}
.seccion-saas{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
}
.info-sass h2{
    text-align: center;
    font-size: 30px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    color: #2294d2;
}
.info-sass h3{
    padding-top: 0px;
    margin-top: 0px;
    font-size: 60px;
    margin-bottom: 0px;
    color: #2294d2;
}

.info-sass p{
    font-size: 25px;
    text-align: center;
    width: 50%;
    margin: 0 auto;
    padding-bottom: 0px;
}
.img-saas{
    grid-area: b;
    text-align: right;
    padding-top: 30px;
}

.img-saas img{
    width: 90%;
}
.button-saas{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.button-saas button{
    font-size: 22px;
    color: #2294d2;
    font-weight: bold;
    border: 2px solid #2294d2;
    background-color: transparent;
    border-radius: 10px;
    padding: 10px;
    width: 250px;
}

.button-saas button:hover{
    cursor: pointer;
    background-color: #2294d2;
    color: white;
}


@media only screen and (max-width: 480px) {
    .info-sass p{
        font-size: 22px;
        text-align: justify;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 0px;
    }

}