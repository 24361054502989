.lumigo_container{
    background-image: url("../../img-components/lumigowall.jpg");
    background-size: cover;
    height: 90vh;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}
.img-lum{
    width: 200px;
}

.capa-lum{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.603);
}

.seccion-lum-info{
    position: relative;
    z-index: 20 !important;
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;
}
.logos-lum{
    display: flex;
}
.img-lum{
    width: 300px !important;
}
.img-hi{
    width: 180px;
}
.line-gg{
    margin-left: 30px;
    margin-right: 30px;
    width: 5px;
    background-color: white;
}
.title-lumig{
    color: white;
    font-size: 40px;
    font-weight: bold;
}
.text-lumigo{
    text-align: justify;
    color: white;
    font-size: 22px;
    font-weight: bold;
}
.footer-lumi{
    height: 15vh;
    background-color: white;
}
.buttonlumigo{
    width: 200px;
    height: 60px;
    border: none;
    border-radius: 10px;
    background-color: #224455;
    font-size: 20px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    float: right;
    margin-top: 20px;
}

@media only screen and (max-width: 720px) {

    .lumigo_container{
        height: auto !important;
    }

}