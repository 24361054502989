.seccion-google-container{
    height: 100vh;
    width: 100%;
    background-image: url("../../img-components/google-wall.jpg");
    background-size: cover;
    position: relative;
    overflow: hidden;
    background-position-y: 25%;
}
.cap-ggole{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.523);
}
.seccion-google-info{
    width: 80%;
    margin: 0 auto;
    position: relative;
    z-index: 200;

}
.logos-goole{
    display: grid;
    grid-template-columns: 15% 5% 18%;
    grid-template-areas: " a b c";
    width: 100%;
    padding-top: 30px;
    height: 100px;
    overflow: hidden;
}
.logos-goole .img-google{
    width: 90px;
}
.logos-goole .img-hi{
    width: 200px;
}

.logos-goole .line-gg{
    width: 5px;
    background-color: white;
}
.desc-google{
    width: 80%;
    margin: 0 auto;
    position: relative;
    z-index: 200;
    height: 50% !important;
    padding-bottom: 90px;
}
.desc-google p{
    font-size: 20px;
    color: white;
    text-align: justify;
    font-weight: bold;
}
.desc-google h2{
    color: white;
    font-size: 40px;
}
.div-google-foot{
    height: 100%;
    background-color: white;
    position: relative;
    z-index: 200;
    display: flex;
}
.div-google-foot p{
    font-size: 20px;
    padding-left: 50px;
    width: 65%;
}
.div-google-foot img{
    width: 200px;
    margin-top: -90px;
    padding-left: 10%;
    height: 200px;
}

@media only screen and (max-width: 480px) {

    .logos-goole{
        grid-template-columns: 30% 10% 20%;
        grid-template-areas: " a b c";
    }
    .logos-goole .img-google{
        width: 80px;
    }
    .logos-goole .img-hi{
        width: 150px;
    }
    
    .logos-goole .line-gg{
        width: 5px;
        background-color: white;
    }
    .desc-google p{
        font-size: 16px;
    }
    .desc-google h2{
        font-size: 25px;
    }
    .div-google-foot p{
        font-size: 18px;
        text-align: justify;
    }
    .div-google-foot img{
        display: none;
    }

}