.carrusel-aws{
   display: flex;
   animation: scrollaws 250s linear infinite;
}  
.carrusel-aws.stop{
    animation: none;
}

.cartaws {
    flex: 0 0 auto;
    margin-right: 20px; 
    width: 300px;
    height: 350px;
    margin-left: 20px;
    border: 1px solid gray;
    overflow-y: auto;
    border-top: 10px solid rgb(255, 169, 40);
    /* background-image: url("../../img-components/slide1-aws.png"); */
    background-size: cover;
    background-position-x: 20%;

}
.cartaws h2{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: rgb(255, 255, 255);
    color: black;
}
.cartaws p{
    padding: 15px;
    font-size: 18px;
    text-align: justify;
    font-weight: 400;
    color: white;
    color: black;
}




@keyframes scrollaws {
    0% {
        /* -webkit-transform: translateX(0); */
        transform: translateX(0);
    }
    100% {
       /* -webkit-transform: translateX(calc(-100px * 22)); */
       transform: translateX(calc(-300px * 12));
    }
}