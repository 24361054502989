.solutions_desc{
    width: 100%;
    background-color: white;
    margin-bottom: 0px;
    height: auto;
    background-image: url("../../../img-components/fondon.png");
    background-size: cover;
    background-position-y: 0%;
    position: relative;
    overflow: hidden;
}
.cappp{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.323);
}
.title-solutions{
    width: 100%;
    height: auto;
    color: white;
    padding-bottom: 5px;
    /* background-color: rgba(0, 0, 0, 0.266); */
}
.text-solu{
    position: relative;
    z-index: 200;
}
.erp-seccions{
    position: relative;
    z-index: 200;
}
.erp-seccion2{
    position: relative;
    z-index: 200;
}

.title-solutions .cap{
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.179);
    width: 100%;
    height: 100%;
    position: absolute;
}
.title-solutions h2{
    position: relative;
    z-index: 200;
}
.title-solutions p{
    z-index: 200;
    position: relative;
}

.text-solu{
    width: 80%;
    margin: 0 auto;
    color: black;
    padding-bottom: 20px;
}
.pp{
    font-size: 25px;
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
}


.solutions_desc h2{
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 10px;
}
.text-solu .p1{
    text-align: left;
    font-size: 25px;
    padding-top: 15px;
    color: rgb(106, 195, 243);
}
.text-solu .p2{
    text-align: left;
    font-size: 22px;
    color: white;
}


.erp-seccions{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 32% 33% 32%;
    grid-template-areas: 
        "a b c";
    grid-gap: 20px;
    padding-bottom: 40px;
}

.erps{
    display: grid;
    grid-template-rows: 40% 60%;
    grid-area: 
    "a"
    "b";
    height: 350px;
    box-shadow: -5px 5px 10px 5px rgba(0, 0, 0, 0.208);
    overflow-y: auto;
    background-color: white;
    border-radius: 5px;
}
.img-cart{
    height: 100%;
    position: relative;
}
.capa-dark-sou{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.238);
    z-index: 150;
}
.img-cart img{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
}

.img-cart .capa-icon{
    position: absolute;
    z-index: 200;
    width: 100%;
}
.capa-icon h2{  
    font-size: 40px;
    color: white;
    text-align: center;
    align-items: center;
    width: 100%;
    padding-top: 10px;
}
.portal-prov{
    padding-top: 0px;
    margin: 0px;
}
.desc-cart{
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify;
    font-size: 18px;
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
}
.desc-cart span{
    text-align: right;
    font-weight: bold;
    float: right;
    padding-bottom: 20px;
}
.desc-cart .span1{
    text-align: right;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 20px;
}

.desc-cart span:hover{
    cursor: pointer;
}

/* erp seccion 2 */
.erp-seccion2{
    height: auto;
    background-color: white;
    /* border-end-end-radius: 100% 50%; */
    width: 100%;
}
.contn-secc2{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "a b";
}
.contsecc01{
    grid-area: a;
}
.contsecc02{
    grid-area: b;
}

.contn-secc2 h2{
    font-size: 25px;
    text-align: left;
}
.contn-secc2 p{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: justify;
}
.img-con{
    text-align: center;
    padding-top: 50px;
}
.img-con img{
    width: 100%;
}

.erp-seccion3{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 50px;
}
.erp-seccion3 h2{
    color: black;
    font-size: 25px;
    text-align: left;
}
.erp-seccion3 p{
    text-align: justify;
    color: black;
    font-size: 20px;
}
.erp-seccion3 img{
    width: 60%;
}

.erp1{grid-area: a;}
.erp2{grid-area: b;}
.erp3{grid-area: c;}
.erp4{grid-area: a;}
.erp5{grid-area: b;}
.erp6{grid-area: c;}


@media only screen and (max-width: 480px) {

    .erp-seccions{
        grid-template-areas: 
            "a a a"
            "b b b"
            "c c c";
        grid-gap: 10px;
    }
    .contn-secc2{
        grid-template-areas: "a a";
    }
    .contsecc02{
        display: none;
    }
    .erp-seccion3 img{
        width: 100%;
    }
    

}

