.aws-seccion{
    width: 100%;
    height: auto;
    background-image: url("../../img-components/fondo2.png");
    background-size: cover;
    background-position-y: 30%;
    overflow: hidden;
}
.capa-oscura{
    position: absolute; 
    width: 100%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.450);
    z-index: 150;
    overflow: hidden;
}

.container-aws{
    width: 100%;
    height: 100%;
    padding-top: 30px;
    position: relative;
    z-index: 200 !important;
}
.logos-aws{
    height: 120px;
    width: 100%;
    overflow: hidden;
    /* margin: 0 auto; */
    display: grid;
    grid-template-columns: 20% 5% 20% 50%;
    grid-template-rows: "a b c";
    margin-left: 5%;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.logos-aws .linea{
    width: 5px;
    height: 100%;
    background-color: white;
}

.logos-aws .log-1{
    width: 240px;
    margin: 0 auto;
}
.logos-aws .log-2{
    width: 150px;
    margin: 0 auto;
}
.eslogan{
    width: 80%;
    margin: 0 auto;
    margin-top: -20px;
}
.eslogan h2{
    font-size: 35px;
    color: white;
    text-align: justify;
    font-weight: bold;
}
.desc-aws{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 80px;
}
.desc-aws p{
    font-size: 20px;
    color: white;
    text-align: justify;
    font-weight: 500;
}
.certificaciones-aws{
    width: 100%;
    /* background-color: rgba(20, 19, 34, 0.679); */
    background-color: white;
    height: 100%;
    display: grid;
    grid-template-columns: 5% 25% 60%;
    grid-template-areas: "a b c";
}
.cart-certi{
    margin-top: -50px;
    border-radius: 5px;
    text-align: left;
    display: flex;
}
.cart-certi img{
    width: 50%;
    height: 90%;
    margin-top: 0px;
    margin-right: 10px;
}
.cart-certi img:hover{
    cursor: pointer;
}


.cart-certi .partn-aws{
    width: 80%;
    overflow: hidden;
    display: flex;
    margin: 0 auto;
}
.partn-aws img{
    width: 80px;
    padding-top: 10px;
}
.partn-aws p{
    text-align: left;
    font-size: 18px;
    padding-left: 10px;
    font-weight: bold;
}
.serv-aws{
    grid-area: c;
    padding-left: 50px;
}
.serv-aws h2{
    font-size: 22px;
}
.serv-colum-aws{
    display: grid;
    grid-template-columns: 40% 40% 30%;
    grid-template-areas: "a b c";
    width: 95%;
    padding-bottom: 20px;
}
.colum1, .colum2{
    border-right: 2px solid rgb(194, 194, 194);
    text-align: left;
    text-decoration: none;
}
.colum1 p{
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: 0px;
}
.colum2 p{
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: 0px;
    padding-left: 30px;
}
.colum3{
    padding-left: 20px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
}
.colum3 button{
    color: black;
    background-color: orange;
    width: 70%;
    border: none;
    border-radius: 10px;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
}
.colum3 button:hover{
    cursor: pointer;
}
.colum1{grid-area: a;}
.colum2{grid-area: b;}
.colum3{grid-area: c;}

@media only screen and (max-width: 480px) {

    .logos-aws .linea{
        width: 5px;
        height: 60%;
        background-color: white;
    }
    
    .logos-aws .log-1{
        width: 100%;
        margin: 0 auto;
        padding-top: 10px;
    }
    .logos-aws .log-2{
        width: 100%;
        margin: 0 auto;
    } 
    .logos-aws{       
        grid-template-columns: 30% 5% 30% 50%;
        grid-template-rows: "a b c";
        margin-left: 5%;
    }
    .eslogan h2{
        font-size: 25px;
    }
    .desc-aws p{
        font-size: 18px;
    }
    .serv-colum-aws{
        grid-template-areas: 
        "a a a"
        "b b b"
        "c c c";
    }    
    .colum2{
        display: none;
    }
    .colum3{
        padding-left: 0px;
        width: 100%;
        text-align: left;
    }

    .cart-certi{
       margin-top: 0px;
       border-radius: 5px;
       text-align: center;
    }
    .cart-certi img{
        width: 100px;
        height: 70%;
        margin-top: -50px;
        margin-left: -20px;
    }

    .certificaciones-aws{
        grid-template-columns: 0% 0% 90%;
        grid-template-areas: "a b c";
    }
    .cart-certi{
        display: none !important;
    }
}