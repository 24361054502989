.footer-container-aws{
    height: auto;
    background-image: url("../../img-components/slide1-aws.png");
    background-size: cover;
    background-position-y: 50%;
    width: 100%;
    overflow: hidden;
}

.footer-seccions{
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas: "a b c d e";
    margin: 0 auto;
    padding-top: 20px;
}
.footer-seccions .logo img{
    width: 150px;
}
.footer-seccions .logo{
    text-align: center;
    padding-top: 20px;
}
.footer-seccions h2{
    color: white;
}
.secc1, .secc2, .secc3, .secc4{
    width: 100%;
    height: 100%;
    border-right: 1px solid gray;
}

.logo{grid-area: a;}
.secc1{grid-area: b;}
.secc2{grid-area: c;}
.secc3{grid-area: d;}
.secc4{grid-area: e;}


.secc1 h2, .secc2 h2, .secc3 h2, .secc4 h2{
    font-size: 25px;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
}
.line-2-aws{
    background-color: rgb(235, 196, 55);
    height: 2px;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.secc1 p, .secc2 p, .secc3 p, .secc4 p{
    font-size: 20px;
    color: white;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
.redes{
    display: flex;
    padding-left: 25px;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
}
.redes svg{
    color: white;
    font-size: 30px;
}
.redes p{
    margin-top: 0px;
}
.redes:hover{
    border: 1px solid white;
    cursor: pointer;
}
.copy{
    height: 60px;
}
.copy p{
    color: white;
    padding-left: 20px;
    padding-top: 20px;
}
.privacidad{
    padding-left: 20px;
}
.privacidad:hover{
    color: rgb(55, 193, 235);
    cursor: pointer;   
}
.modal-text{
    width: 70%;
    min-width: 70% !important;
    max-width: 70% !important;
    text-align: center;
    padding-top: 30px;
}


@media only screen and (max-width: 720px) {

    .footer-seccions{
        grid-template-areas: 
        "b b b c c"
        "d d d e e"
        "a a a a a" 
        !important;
        
    }

}

@media only screen and (max-width: 480px) {
    .footer-seccions{
        grid-template-areas: 
        "b b b b b"
        "c c c c c"
        "d d d d d"
        "e e e e e"
        "a a a a a" 
        !important;
    }
    .copy{
        padding-bottom: 20px;
    }
}