.tarjeta-div{
    width: 100%;
    height: auto;   
}
.slider-columns-tarjeta{
    background-image: url("../img-components/tarjeta2.png");
    background-repeat: none;
    background-size: cover;
    width: 100%;
    height: 170px;
    background-position-y:0%;
}
.logo-hi{
    width: 120px;
    right: 0;
    margin-top: 20px;
}
.logo-hi:hover{
    cursor: pointer;
}
.foto{
    margin: 0 auto;
    text-align: center;
}
.foto img{
    width: 250px;
    border-radius: 50%;
    margin-top: -150px;
}
.title{
    font-size: 45px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: -40px;
}
.p-text{
    color: gray;
    font-size: 30px;
    text-align: center;
    margin-top: -50px;
}
.columns{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 90%;
    grid-template-areas: "a b";
    margin: 0 auto;
    grid-gap: 10px;
}
.certificaciones{
    background-image: url("../img-components/serv1.png");
    background-size: cover;
    grid-area: a;
    text-align: center;
    border-top-left-radius: 20px;
}
.certificaciones h2{
    text-align: center;
    color: white;
    font-size: 40px;
    margin-bottom: 10px;
}
.certificaciones img{
    width: 20%;
}

.info-ivan h2{
    text-align: center;
    color: white;
    font-size: 20px;
    margin-top: 0px;
}
.info-ivan p{
    font-size: 25px;
    color: white;
}
.info-ivan p span{
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
    color: #69b9ff;
}
.info-ivan{
    grid-area: b;
    background-image: url("../img-components/tarjeta.png");
    background-size: cover;
    text-align: center;
    border-top-right-radius: 20px;
    background-position-y: 50%;
}

@media only screen and (max-width: 480px) {
    .foto img{
        width: 200px;
        margin-top: -100px;
    }
    .title{
        font-size: 25px;
        margin-top: -20px;
    }
    .p-text{
        margin-top: -15px;
        font-size: 25px;
    }
    .columns{
        width: 90%;
        grid-template-areas: 
        "b b"
        "a a";
        grid-gap: 0px;
    }

    .info-ivan p span{
        font-weight: bold;
        font-size: 15px;
    }
    .info-ivan{
        background-position-x: 100%;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    .certificaciones{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .certificaciones h2{
        font-size: 20px;
    }
    .certificaciones img{
        width: 40%;
    }
    .slider-columns-tarjeta{
        height: 130px;
    }
    .info-ivan{
        background-image: url("../img-components/tarjeta-movil.png");
    }
}


