.invoice-container{
    width: 100%;
    background-image: url("../../../img-components/fondo-15.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 50px;
}
.invoice-container H2{
    color: #2294d2;
}

.invoice-section{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas: "a b";
}
.inv-sec-g1{
    grid-area: a;
    text-align: left;
}
.inv-sec-g1 img{
    width: 90%;
    padding-top: 50px;
}

.inv-sec-g2{
    grid-area: b;
}
.inv-sec-g2 h2{
    font-size: 45px;
    text-align: center;
    font-weight: bold;
}
.inv-sec-g2 p{
    font-size: 25px;
    text-align: justify;
}

.expense-section{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 55% 45%;
    grid-template-areas: "a b";
    margin-top: -100px;
}
.expense-section .exp-sec-g1{
    grid-area: b;
}
.expense-section .exp-sec-g2{
    grid-area: a;
    text-align: right;
}
.expense-section .exp-sec-g2 img{
    width: 65%;
}
.expense-section .exp-sec-g1 h2{
    font-size: 45px;
    text-align: center;
}
.expense-section .exp-sec-g1 p{
    font-size: 25px;
    text-align: justify;
    width: 90%;
    margin: 0 auto;
}

.supli-section{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas: "a b";
    margin-top: -120px;
}
.sup-sec-g1{
   grid-area: a;
   text-align: left; 
}
.sup-sec-g1 img{
    width: 90%;
}
.sup-sec-g2{
    grid-area: b;
    padding-top: 100px;
}

.sup-sec-g2 h2{
    font-size: 45px;
    text-align: center;
}
.sup-sec-g2 p{
    font-size: 25px;
    text-align: justify;
}
.text-a-sup{
    background-color: #092c3e;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
}
.text-a-sup p{
    font-size: 25px;
    color: white;
}

@media only screen and (max-width: 480px) {

    .invoice-container{
        background: none;
        padding-bottom: 0px;
    }
   
    .invoice-section{
        grid-template-columns: 40% 60%;
        grid-template-areas: 
        "b b"
        "a a";
    }
    .inv-sec-g1{
        grid-area: a;
        text-align: center;
    }
    .inv-sec-g1 img{
        width: 80%;
        padding-top: 0px;
        margin-top: 20px;
    }

    .inv-sec-g2{
        grid-area: b;
    }
    .inv-sec-g2 h2{
        margin-bottom: 0px;
    }
    .inv-sec-g2 p{
        font-size: 22px;
        text-align: justify;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }


    .expense-section{
        grid-template-areas: 
        "b b"
        "a a";
        margin-top: 0px;
    }
    .expense-section .exp-sec-g1 h2{
        font-size: 40px;
    }
    .expense-section .exp-sec-g1 p{
        font-size: 22px;
        padding-bottom: 30px;
    }
    .expense-section .exp-sec-g2{
        text-align: center;
    }
    .expense-section .exp-sec-g2 img{
        width: 80%;
    }

        
    .supli-section{
        grid-template-areas: 
        "b b""a a";
        margin-top: 0px;
    }
    .sup-sec-g1{
    grid-area: a;
    text-align: center; 
    padding-top: 20px;
    }
    .sup-sec-g1 img{
        width: 80%;
    }
    .sup-sec-g2{
        grid-area: b;
        padding-top: 0px;
    }

    .sup-sec-g2 h2{
        font-size: 40px;
        text-align: center;
    }
    .sup-sec-g2 p{
        font-size: 22px;
        text-align: justify;
        padding-bottom: 20px;
    }
    .text-a-sup{
        background-color: #092c3e;
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .text-a-sup p{
        font-size: 22px;
        color: white;
    }

}