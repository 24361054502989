.services-cloud-container{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    overflow-x: hidden;
}
.services-cloud-title{
    background-image: url("../../img-components/aws-servs.jpg");
    background-size: cover;
    height: 300px;
    background-position-y: 20%;
    position: relative;
}
.services-cloud-title h2{
    width: 50%;
    text-align: center;
    margin: 0 auto;
    font-size: 50px;
    color: white;
    align-items: center;
    padding-top: 100px;
    position: relative;
    z-index: 10 !important;
}
.services-cloud-body{
    width: 70%;
    margin: 0 auto;
    text-align: center;
}
.services-cloud-body h2{
    font-size: 30px;
    text-align: center;
}
.services-cloud-body p{
    font-size: 20px;
    text-align: justify;
}
.services-cloud-columns{
   width: 70%;
   margin: 0 auto;
   padding-bottom: 20px;
}
.gridcloud{
    display: grid;
    width: 100%;
    margin: 0 auto;
    grid-template-columns: 10% 25% 65%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
}

.gridcloud img{
    grid-area: a;
    width: 80%;
    padding-top: 20px;
}

.gridcloud h2{
    font-size: 22px;
    grid-area: b;
    padding: 10px;
}
.gridcloud p{
    font-size: 20px;
    grid-area: c;
    border-left: 2px solid gray;
    padding: 10px;
    text-align: justify;
    margin-bottom: 0px;
}

.services-cloud-soluciones{
    background-color: rgb(0, 0, 0);
    background-image: url("https://cdn.wallpapersafari.com/90/66/1mc0KI.jpg");
    background-size: cover;
}

.services-cloud-soluciones h2{
    text-align: center;
    color: white;
    font-size: 35px;
    padding-top: 40px;
}
.linea-title{
    width: 500px;
    margin: 0 auto;
    text-align: center;
    background-color: #e08e1a;
    height: 2px;
    margin-bottom: 50px;
}
.services-sol-grid{
    display: grid;
    width: 70%;
    margin: 0 auto;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: "a b c";
    grid-gap: 15px;
    padding-bottom: 20pX;
}
.services-sol-grid p{
    color: white;
    font-size: 20px;
}
.services-sol-grid h2{
    font-size: 25px;
    color: #e08e1a;
    margin-top: 0px;
    padding-top: 5px;
    font-weight: bold;
}
.imgsolucloud{
    width: 70px;
    text-align: center;
    margin: 0px;
}
.services-sol-grid div{
    text-align: center;
}
.solgrid1{grid-area: a;}
.solgrid2{grid-area: b;}
.solgrid3{grid-area: c;}
.solgrid4{grid-area: a;}
.solgrid5{grid-area: b;}


@media only screen and (max-width: 720px) {


    .services-cloud-title h2{
        font-size: 40px;
    }

    .gridcloud{
        grid-template-areas: 
        "b b b"
        "c c c";
        grid-gap: 10px;
    }
    .gridcloud img{
        display: none;
    }
    .gridcloud p{
        border-left: none;
        padding-top: 0px;
        margin-top: 0px;
    }

    .services-sol-grid{
        grid-template-areas: 
        "a a a"
        "b b b"
        "c c c";
        grid-gap: 0px;
    }
    .services-cloud-soluciones{
       background-position-x: 30%;
    }
}



/* seccion de base de datos */
.adv-h2{
    font-size: 25px;
    color: white;
    text-align: center;
    position: relative;
    z-index: 10;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}
.services-cloud-soluciones-bd{
    background-color: rgb(0, 0, 0);
    background-image: url("https://cdn.wallpapersafari.com/65/79/J4UE1s.jpg");
    background-size: cover;
    position: relative;
}
.services-cloud-soluciones-bd h2{
    text-align: center;
    color: white;
    font-size: 35px;
    padding-top: 40px; 
    z-index: 10;
    position: relative;
}

.services-sol-grid-bd{
    display: grid;
    width: 70%;
    margin: 0 auto;
    grid-template-columns: 50% 50%;
    grid-template-areas: 
    "a b"
    "c d";
    grid-gap: 20px;
    padding-bottom: 20PX;
    z-index: 10;
    position: relative;
}

.services-sol-grid-bd .solgrid1{
    grid-area: a;
}
.services-sol-grid-bd .solgrid2{
    grid-area: b;
}
.services-sol-grid-bd .solgrid3{
    grid-area: a;
}
.services-sol-grid-bd .solgrid4{
    grid-area: b;
}



.services-sol-grid-bd p{
    color: white;
    font-size: 20px;
}
.services-sol-grid-bd h2{
    font-size: 25px;
    color: #4fbfe4;
    margin-top: 0px;
    padding-top: 5px;
    font-weight: bold;
}
.services-sol-grid-bd div{
    text-align: center;
}
.linea-title-bd{
    width: 500px;
    margin: 0 auto;
    text-align: center;
    background-color: #4fbfe4;
    height: 4px;
    margin-bottom: 50px;
    position: relative;
    z-index: 10;
}


@media only screen and (max-width: 720px) {
    .services-sol-grid-bd{
        grid-template-areas: 
        "a a a"
        "b b b"
        "c c c";
        grid-gap: 0px;
    }
    .services-cloud-soluciones-bd{
       background-position-x: 50%;
    }
}

/* seccion adopcion */

.services-cloud-soluciones-adop{
    background-color: rgb(0, 0, 0);
    background-image: url("https://d2908q01vomqb2.cloudfront.net/b3f0c7f6bb763af1be91d9e74eabfeb199dc1f1f/2021/05/14/AWS3862_SAP-HMI-Blog-Post_BN-1024x576-1.png");
    background-size: cover;
    position: relative;
}
.services-cloud-soluciones-adop h2{
    text-align: center;
    color: white;
    font-size: 35px;
    padding-top: 40px; 
    z-index: 10;
    position: relative;
}

.services-sol-grid-adop{
    display: grid;
    width: 70%;
    margin: 0 auto;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: "a b c";
    grid-gap: 20px;
    padding-bottom: 20PX;
    z-index: 10;
    position: relative;
}
.services-sol-grid-adop p{
    color: white;
    font-size: 20px;
}
.services-sol-grid-adop h2{
    font-size: 25px;
    color: #a697ee;
    margin-top: 0px;
    padding-top: 5px;
    font-weight: bold;
}
.services-sol-grid-adop div{
    text-align: center;
}
.linea-title-adop{
    width: 500px;
    margin: 0 auto;
    text-align: center;
    background-color: #463792;
    height: 4px;
    margin-bottom: 50px;
    position: relative;
    z-index: 10;
}

@media only screen and (max-width: 720px) {
    .services-sol-grid-adop{
        grid-template-areas: 
        "a a a"
        "b b b"
        "c c c";
        grid-gap: 0px;
    }
    .services-cloud-soluciones-bd{
       background-position-x: 50%;
    }
    .services-cloud-title h2{
        width: 80% !important;
    }
    .services-cloud-title{
        height: auto;
        padding-bottom: 10px;
    }
}

