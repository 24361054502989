.container-services{
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 50px;
    background-position-y: 10%;
    background-size: cover;
}
.p-services{
    font-size: 35px;
    font-weight: bold;
    color: black;
    text-align: left;
    margin-top: 50px;
}
.p-services span{
    color: rgb(70, 172, 215);
}
.desc-serv-intro{
    text-align: justify;
    color: black;
    font-size: 20px;
}
.services-intro{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.services-intro img{
    width: 100%;
}
.seccion-services{
    display: grid;
    grid-template-columns: 30% 23% 23% 23%;
    grid-template-areas: "a b c d";
    grid-gap: 20px;
    width: 80%;
    margin: 0 auto;
}
.serv-1, .serv-2, .serv-3{
    width: 100%;
    border: 1px solid black;
    color: white;
    border-radius: 10px;
    box-shadow: -5px 5px 10px 5px rgba(0, 0, 0, 0.208);
}
.serv-1{
    background-image: url("../../img-components/slider/slider3.png");
}
.serv-2{
    background-image: url("../../img-components/slider/slider3.png");
}
.serv-3{
    background-image: url("../../img-components/slider/slider3.png");
}

.serv-1:hover, .serv-2:hover, .serv-3:hover{
    transform: scale(1.05);
}


.cart-services{
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: right;
}
.cart-services svg{
    font-size: 50px;
    padding-top: 20px;
}
.cart-services h2{
    margin-top: 0px;
    height: 50px;
    align-items: center;
    text-align: left;
}
.cart-services p{
    font-size: 18px;
    text-align: justify;
}
.services-intro{grid-area: a;}
.serv-1{grid-area: b;}
.serv-2{grid-area: c;}
.serv-3{grid-area: d;}


@media only screen and (max-width: 480px) {
    .seccion-services{
        grid-template-areas: 
        "a a a a"
        "b b b b"
        "c c c c"
        "d d d d";
        grid-gap: 10px;
    }
    .p-services{
        margin-top: 0px;
        text-align: center;
        color: white;
    }
}