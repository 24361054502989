.cap-fond{
    background-image: url("../../../img-components/fondo-13.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 10%;
}
.sales-grid-cpm{
    display: grid;
    width: 90%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    padding-top: 50px;
    margin: 0 auto;
}
.salesg1{
    grid-area: a;
    text-align: center;
}
.salesg1 img{
    width: 70%;
}
.salesg2{
    grid-area: b;
    padding-top: 20px;
}

.salesg2 h2{
    font-size: 50px;
    text-align: center;
}
.salesg2 p{
    font-size: 25px;
    text-align: justify;
}
.sales-real-mc{
    width: 100%;
    overflow: hidden;
}
.sales-real-grid-mc{
    display: grid;
    width: 90%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    margin: 0 auto;
}
.salesrg1{
    grid-area: a;
}

.salesrg1 h2{
    font-size: 50px;
    text-align: center;
}
.salesrg1 p{
    font-size: 25px;
    text-align: justify;
    padding: 15px;
}

.salesrg2{
    grid-area: b;
    text-align: center;
}
.salesrg2 img{
    width: 80%;
}
.box-real-sales{
    background-color: #161342;
    border-radius: 10px;
}
.box-real-sales p{
    font-size: 25px;
    color: white;
}

/* slogan movil seccion */
.slogan-movil{
    width: 100%;
    text-align: center;
    padding-top: 80px;
}
.slogan-text{
    width: 90%;
    margin: 0 auto;
    color: white;
    border-radius: 20px;
    background: rgb(60,83,182);
    background: linear-gradient(156deg, rgba(60,83,182,1) 43%, rgba(65,163,236,1) 78%, rgba(102,216,247,1) 94%);
    position: relative;
    height: auto;
    overflow: hidden;
}
.text-ghost{
    color: #556FDB;
    font-size: 100px;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0px;
    padding-top: 0px;
    text-align: left;
}
.text-promotion{
    font-size: 50px;
    color: white;
    font-weight: bold;
}
.text-promotion2{
    font-size: 45px;
    color: white;
    font-weight: bold;
    margin-top: 0px;
    padding-top: 0px;
    text-align: center;
}
.grid-text-promotion{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    position: relative;
    z-index: 20;
    padding-top: 20px;
    width: 90%;
    margin: 0 auto;
}
.grid-text-promotion img{
    padding-top: 50px;
}
.grdi-slog-g1{grid-area: a;}
.grdi-slog-g2{grid-area: b;}


@media only screen and (max-width: 480px) {
    .cap-fond{
        background: none;
    }
    .sales-grid-cpm{
        grid-template-areas: 
        "b b"
        "a a";
        padding-top: 0px;
    }
    .salesg1 img{
        width: 80%;
    }
    .salesg2{
        padding-top: 0px;
    }
    .salesg2 h2{
        font-size: 45px;
        text-align: center;
    }
    .salesg2 p{
        font-size: 22px;
        text-align: justify;
    }


    .sales-real-grid-mc{
        grid-template-areas: 
        "a a"
        "b b";
    }
    .salesrg1 h2{
        font-size: 45px;
        text-align: center;
    }
    .salesrg1 p{
        font-size: 22px;
        text-align: justify;
    }
    .box-real-sales p{
        font-size: 22px;
        color: white;
    }
    .salesrg2 img{
        width: 90%;
        margin-top: 20px;
    }
    .slogan-movil{
        width: 100%;
        text-align: center;
        padding-top: 50px;
    }
    .text-promotion{
        font-size: 35px;
        color: white;
        font-weight: bold;
    }
    .text-promotion2{
        font-size: 35px;
        color: white;
        font-weight: bold;
        margin-top: 0px;
        padding-top: 0px;
        text-align: center;
    }
    .grid-text-promotion{
        grid-template-areas: 
        "a a"
        "b b";
    }
    .grid-text-promotion{
        text-align: center;
    }
    .grid-text-promotion img{
        padding-top: 30px;
        text-align: center;
        margin: 0 auto;
    }
    
}