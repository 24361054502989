.slide_container-sc{
    width: 100%;
    height: 70vh;
    background-image: url("../../../img-components/cloud-slide.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 20%;
    background-color: #000450;
}
.slide_container-sc .slid-grid{
    width: 90%;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-areas: "a b";
    margin: 0 auto;
    padding-top: 50px;
}
.slide_container-sc .slide-text{
    color: white; 
    grid-area: a;
    overflow: hidden;
}
.slide_container-sc h2{
    font-size: 70px;
    text-align: left;
    margin-bottom: 0px;
}
.slide_container-sc .slide-text p{
    font-size: 25px; 
    text-align: justify;
    width: 80%;
}

.slide_container-sc .medalla-aws{
    grid-area: b;
    margin-top: 50px;
    text-align: left;
    padding-left: 0px;
    margin-left: 0px;
}
.slide_container-sc .medalla-aws img{
    width: 100%;
    margin-top: 50px;
}
.slide_container-sc .medalla-aws img:hover{
    cursor: pointer;
}


@media only screen and (max-width: 480px) {
    .slide_container-sc .slid-grid{
        grid-template-areas: 
        "a a";
    }
    .slide_container-sc .medalla-aws{
        display: none;
    }
    .slide-text h2{
        font-size: 40px;
        text-align: center;
    }
    .slide-text p{
        font-size: 24px !important;
        text-align: justify;
        width: 100% !important;
    }
}
