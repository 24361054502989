.petition-container{
    width: 100%;
    background-image: url("../../../img-components/fondo-12.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 30%;
}
.petition-secction{
    width: 90%;
    margin: 0 auto;
    text-align: center;
}
.pett-grid{
    display: grid;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    padding-top: 50px;
}
.pett-grid .pettg1{
    grid-area: a;
}
.pett-grid .pettg1 h2{
    font-size: 50px;
    color: black;
}
.pett-grid .pettg1 p{
    font-size: 25px;
    text-align: justify;
}
.pett-grid .pettg2{
    grid-area: b;
    text-align: center;
}
.pett-grid .pettg2 img{
    width: 70%;
}



@media only screen and (max-width: 480px) {

    .pett-grid{
        grid-template-areas: 
        "a a"
        "b b";
        padding-top: 0px;
    }
    .pett-grid .pettg1 h2{
        font-size: 40px;
    }
    .pett-grid .pettg1 p{
        font-size: 22px;
    }
    .pett-grid .pettg2 img{
        width: 80%;
    }
    

}