.navbar-container{
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 200 !important;
}
.grid-menu{
    display: grid;
    width: 100%;
    grid-template-columns: 10% 90%;
    grid-template-rows: "a b";
}
.icono{
    padding: 10px;
}
.icono img{
    padding-left: 20px !important;
    width: 100px;
}
.menu-items {
    display: flex;
    justify-content: end;
    margin-right: 50px;
}

  .ul-items {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 10px;
  }

  .ul-items li {
    margin: 10px;
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    margin-left: 20px;
  }
  .ul-items li a{
    text-decoration: none;
    font-size: 20px;
    color: white;
    margin: 0 auto;
}
.ul-items li a::after {
    content: "";
    width: 0;
    height: 2px;
    background-color: white; /* Color del subrayado */
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }
  .ul-items li a:hover::after {
    width: 100%;
  }

  /* imagen icono */
  .icon-img {
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    transform: translatex(0px) translateY(20px);
  }
  .ul-items li:hover .icon-img {
    transform: translateX(0px) translateY(0px);
    opacity: 1;
    filter: invert(1);
  }

  /* Estilo para el menú desplegable de idioma */
.language-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Estilo para las opciones de idioma */
.language-options {
  display: none;
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: auto; /* El ancho se ajustará automáticamente según el contenido */
  overflow: hidden; /* Añade un desbordamiento oculto si el contenido es más ancho */
  background-color: rgba(0, 0, 0, 0.023);
}

.language-dropdown:hover .language-options {
  display: block;
}

.language-options li {
  padding: 8px 12px;
}
 
.language-dropdown{
  text-align: right;
  font-size: 22px;
  color: white;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .ul-items {
    display: none;
  }
  .menu-flotante-cel-hi{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-image: url("../../img-components/slider/slider1.png");
  }
  .menu-flotante-cel-hi .menu-flotante-items{
    margin-top: 60px;
  }
  .menu-flotante-cel-hi .menu-flotante-items li{
    color: white;
    font-size: 30px;
    list-style: none;
    text-align: center;
    height: 50px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}



