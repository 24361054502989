.asset-container{
    width: 100%;
    background-color: #f9f9f9;
}
.assets-section{
    width: 90%;
    margin: 0 auto;
    padding-top: 10px;
}
.assets-section h2{
    text-align: center;
    font-size: 50px;
    margin-bottom: 0px;
}
.columns-assts{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
}
.colass1{
    grid-area: a;
}
.colass1 p{
    text-align: justify;
    font-size: 25px;
    color: black;
    width: 90%;
}
.colass2{
    grid-area: b;
}
.colass2 p{
    text-align: justify;
    font-size: 25px;
    color: black;
}

.asst-icon-desc{
    text-align: center;
}
.asst-icon-desc img{
    width: 80%;
}


@media only screen and (max-width: 480px) {
    .columns-assts{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-areas: 
        "a a"
        "b b";
    }
    .colass1 p{
        font-size: 22px;
        width: 95%;
    }
    .colass2 p{
        font-size: 22px;
        width: 95%;
    }
    .assets-section h2{
        font-size: 40px;
    }
    .asst-icon-desc img{
        width: 100%;
    }


}