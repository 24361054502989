.Services-container{
    width: 100%;
    height: auto;
    overflow: auto;
    padding-bottom: 20px
}
.services-solutions{
    width: 80%;
    margin: 0 auto;
}
.services-solutions h2{
    font-size: 35px;
}
.services-solutions p{
    font-size: 20px;
    text-align: justify;
}
.services-items{
    width: 100%;
    background-color: rgb(241, 249, 255);
    background-image: url("../../img-components/slide1-aws.png");
    background-size: cover;
    position: relative;
    background-position-y: 40%;
}
.grid-items{
    position: relative;
    z-index: 150 !important;
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-template-areas: "a b c d";
    padding-top: 25px;
    padding-bottom: 25px;
    grid-gap: 12px;
    color: white;
}
.si1{grid-area: a;}
.si2{grid-area: b;}
.si3{grid-area: c;}
.si4{grid-area: d;}

.si1, .si2, .si3{
    border-right: 1px solid rgb(209, 209, 208);
}

.grid-items p{
   font-size: 18px;
    margin-bottom: 5px;
    margin-top: 0px;
    padding-right: 20px;
    text-align: justify;

}
.grid-items .title{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-left: 0px;
}

@media only screen and (max-width: 720px) {
    .grid-items{
        grid-template-areas: 
        "a a b b"
        "c c d d";
    }
    .si1, .si2, .si3{
        border-right: none;
    }
}


@media only screen and (max-width: 480px) {
    .grid-items{
        grid-template-areas: 
        "a a a a"
        "b b b b"
        "c c c c"
        "d d d d";
    }
    .si1, .si2, .si3{
        border-right: none;
    }
}
