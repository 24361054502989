.container-seccion{
    width: 100%;
    height: auto;
    background-size: cover;
    overflow: hidden;
    margin-bottom: 0px;
    background-position-y: 90%;
    background-image: url("../../img-components/valores.png");
}
.seccionA{
    height: auto;
    width: 100%;
    padding-top: 20px;
    text-align: center
}

.seccionA P{
    padding: 0%;
    margin: 0%;
}
.title2{
    color: #014b79;
    font-weight: bold;
    font-size: 25px;
}
.title1{
    color: #014b79;
    font-weight: bold;
    font-size: 40px;
    padding-top: 0px;
    margin-top: 0px;
}

.div-seccionA-1{
    width: 50%;
    margin: 0 auto;
}
.div-seccionA-2{
    width: 90%;
    margin: 0 auto;
}
.text-sec2{
    font-size: 24px;
    padding-top: 10px !important;
    width: 70%;
    margin: 0 auto !important;
}
.div-seccionA-3{
    width: 80%;
    margin: 0 auto;
}
.secc3a, .secc3b, .secc3c{
    display: grid;
    grid-template-columns: 35% 30% 35%;
    grid-template-areas: "a b c";
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}
.secc3a .grid1{grid-area: a;}
.secc3a .grid2{grid-area: b;}
.secc3a .grid3{grid-area: c;}

.secc3b .grid1{grid-area: a;}
.secc3b .grid2{grid-area: b;}
.secc3b .grid3{grid-area: c;}

.secc3c .grid1{grid-area: a;}
.secc3c .grid2{grid-area: b;}
.secc3c .grid3{grid-area: c;}



.grid1{
   width: 100%;
}
.grid2{
    width: 100%;
    position: relative;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}
.grid3{
  width: 100%;
}
.fondo-icon{
    margin: 0 auto;
    border-radius: 50%;
    background-color: #014b79;
    width: 150px;
    height: 150px;
    overflow: hidden;
}
.fondo-icon2{
    margin: 0 auto;
    border-radius: 50%;
    background-color: #128aa0;
    width: 150px;
    height: 150px;
    overflow: hidden;
}
.fondo-icon3{
    margin: 0 auto;
    border-radius: 50%;
    background-color: #1bc6ea;
    width: 150px;
    height: 150px;
    overflow: hidden;
}
.fondo-icon img, .fondo-icon2 img, .fondo-icon3 img{
    width: 60%;
    height: 60%;
    margin-top: 30px;
    text-align: center;
    align-items: center;
}

@keyframes saludo {
    0%, 100% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
}

#cart1{grid-area: a;}
#cart2{grid-area: b;}
#cart3{grid-area: c;}
#cart4{grid-area: d;}
#cart5{grid-area: e;}
#cart6{grid-area: f;}

@media only screen and (max-width: 480px) {
    .title1{
        font-size: 30px;
    }
    .text-sec2{
        font-size: 20px;
        width: 90%;
    }
    
    .secc3a{
        grid-template-areas: "a a a";
    }
    .secc3b{
        grid-template-areas: "c c c";
    }
    .secc3c{
        grid-template-areas: "a a a";
    }
    .secc3a .grid2{display: none;}
    .secc3a .grid3{display: none;}
    .secc3b .grid1{display: none;}
    .secc3b .grid2{display: none;}
    .secc3c .grid2{display: none;}
    .secc3c .grid3{display: none;}


    .carts-values{
        grid-template-rows: 15% !important;
        grid-template-areas: 
        "a a a"
        "b b b"
        "c c c"
        "d d d"
        "e e e"
        "f f f" !important;
    }
    .container-seccion2{
        height: auto !important;
    }
}

.fondo-icon img, .fondo-icon2 img, .fondo-icon3 img {
    transition: transform 0.3s ease-in-out;
}

.fondo-icon img:hover, .fondo-icon2 img:hover, .fondo-icon3 img:hover {
    animation: saludo 1s ease-in-out infinite; 
}

.Mision-p{
    border: 1px solid #014b79;
    background-color: #ffffffd3;
    width: 100% !important;
    border-radius: 10px;
    padding: 10px;
}
.Mision-p h2{
    text-align: left;
    padding-top: 0%;
    margin-top: 0%;
    font-size: 20px;
}
.Mision-p p{
    text-align: left;
    font-size: 18px;
}
.line{
    /* position: relative; */
    height: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: #014b793f;
    width: 3px !important;
}


/* -------------------------valores ---------------------*/

.container-seccion2{
    height: 100vh;
    /* background-color: #014b79; */
    background-image:  url("../../img-components/slider/slider1.png");
    background-image: url("../../img-components/fondo7.png");
    background-size: cover;
    padding-bottom: 70px;
    margin-bottom: 0px;
    overflow: hidden;
    background-position-y: 90%;
}
.capa-black{
    position: absolute; 
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.450);
    z-index: 150;
}
.container-seccion2 h2{
    text-align: center;
    font-size: 50px;
    color: white;
    margin-top: 10px;
    position: relative;
    z-index: 200 !important;
}
.carts-values{
    display: grid;
    margin: 0 auto;
    width: 90%;
    height: 90%;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 45% 45%;
    grid-template-areas: 
    "a b c"
    "d e f";
    grid-gap: 20px;
    position: relative;
    z-index: 200 !important;
}


.cart-box{
    width: 100%;
    height: 290px;
    position: relative;
    perspective: 1000px;
    overflow: hidden;
    border-radius: 10px;
}
.cart-box:hover .cart {
    transform: rotateY(180deg);
}

.cart-box .cart {
    transform-style: preserve-3d;
    transition: all 0.5s linear;
}
.cart-box .cart .cara {
    position: absolute;
    backface-visibility: hidden;
    overflow: hidden;
    transform: rotateY(360deg);
}
.cart-box .cart .cara.detras {
    transform: rotateY(180deg);
    background-color: black;
}

.cart .cara{
    width: 100%;
    height: 400px !important;
    color: white;
}

.cart .cara img{
    width: 100%;
    height: 100%;
    /* filter: grayscale(100%); */
}
.oscuro-div{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 200 !important;
    background-color: #00000083;
    color: white;
}
.cart-box .cart .cara h2{
    position: absolute;
    top: 30%;
    left: 5%;
    width: 90%;
    text-align: center;
    font-size: 45px;
    background-color: #00000049;
    height: 50px;
    overflow: hidden;
    padding: 0%;
    padding-bottom: 20px;
}



.cart-box .cart .cara p{
    z-index: 250 !important;
    position: absolute;
    top: 0; 
    left: 5%;
    width: 90%;
    text-align: center;
    font-size: 22px;
    color: white;
    padding-top: 12%;
}