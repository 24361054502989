.inventory-seccion{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    width: 90%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}
.inventory-container{
    width: 100%;
    height: auto;
}
.invg1{
    grid-area: a;
    text-align: center;
}
.invg1 img{
    width: 70%;
    padding-top: 50px;
}
.invg2{
    grid-area: b;
    width: 100%;
}
.inv-info-pag{
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 30px;
}
.inv-info-pag h2{
    font-size: 50px;
    color: black;
    text-align: center;
    margin-bottom: 0px;
}
.inv-info-pag p{
    font-size: 25px;
    text-align: justify;
}
.iconos-pinv img{
    width: 100%;
}


@media only screen and (max-width: 480px) {

    .inventory-seccion{
        grid-template-areas: 
        "b b"
        "a a";
        padding-top: 0px;
        padding-bottom: 50px;
    }
    .invg1 img{
        display: none;
    }
    .invg2{
        grid-area: b;
        width: 100%;
    }
    .inv-info-pag{
        width: 95%;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 0px;
    }
    .inv-info-pag h2{
        font-size: 40px;
        color: black;
        text-align: center;
        margin-bottom: 0px;
    }
    .inv-info-pag p{
        font-size: 22px;
        text-align: justify;
    }
    .iconos-pinv img{
        width: 100%;
    }
}