.certificates-container{
    height: auto;
    background-size: cover;
    padding-bottom: 20px;
}
.fondo-wallp-aws{
    width: 100%;
}

/* seccion de ventajas */
.aws-advantages{
    background-color: white;
    height: auto;
    padding-bottom: 50px;
}
.advantages-seccion{
    width: 100%;
}
.title-advtanges{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
} 
.advantages-grid{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: 
     "a b c";
    grid-gap: 15px;
    width: 85%;
    margin: 0 auto;
}

.adv1{
    background-image: url("../../img-components/serv2.png");
    background-size: cover;
    grid-area: a;
}
.adv2{
    background-image: url("../../img-components/serv1.png");
    background-size: cover;
    grid-area: b;
}
.adv3{
    background-image: url("../../img-components/serv3.png");
    background-size: cover;
    grid-area: c;
}

.advitem:hover{
    transform: scale(1.05);
    cursor: pointer;
}

.advitem{
    width: 100%;
    height: 220px;
    border-radius: 5px;
}

.advitem p{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: white;
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;
    text-transform: uppercase;
}

@media only screen and (max-width: 720px) {
    .advitem p{
        font-size: 22px;
    }
}

@media only screen and (max-width: 480px) {
    .advantages-grid{
       grid-template-areas: 
       "a a a"
       "b b b"
       "c c c";
       width: 80%;
       margin-left: 7%;
    } 
    .title-advtanges{
        font-size: 30px;
        padding: 10px;
    } 
    .advitem p{
        font-size: 30px;
    }
}
  

/* certificaciones */

.certi-aws{
    height: auto;
    background: #fff fixed no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-bottom: 15px;
}
#aws-img1{
    background-image: url(../../img-components/aws-wallp.png);
}
.capa{
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.462);
}
.certi-aws-text{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 10;
    padding-top: 20px;
}
.h2-cert{
    font-size: 80px;
    color: white;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
.h2-cert span{
    color: rgb(255, 233, 33);
}
.certi-aws-text p{
    padding-top: 0px;
    margin-top: 0px;
    font-size: 30px;
    color: white;
    font-weight: bold;
}
.comp_aws{
    background-color: white;
    height: 200px;
    overflow-x: hidden;
}
.escudos-aws{
    text-align: center;
}
.escudos-aws img{
    width: 300px;
}



@media only screen and (max-width: 720px) {

    .h2-cert{
        font-size: 60px;
    }
    .certi-aws-text p{
        font-size: 30px;
        padding: 10px;
    }
    .escudos-aws img{
        width: 30%;
    }
}

@media only screen and (max-width: 480px) {
    .h2-cert{
        font-size: 40px;
    }
    .certi-aws-text p{
        font-size: 20px;
        padding: 10px;
    }
    .escudos-aws img{
        width: 35%;
    }
}


/* competencias aws */
.comp_aws{
    width: 100%;
    height: auto;
    padding-bottom: 20px;
}
.comp_aws p{
    font-size: 30px;
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
}


