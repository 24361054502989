.container-slider{
    height: 100vh;
    overflow: hidden;
    z-index: 0;
}
.hero-slider-overlay {
    color: white;
    background-color: rgba(0, 0, 0, 0.15);
  }
  .container-text{
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: l;
    flex-flow: column;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .title-slider{
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    padding-left: 40px;
    padding-top: 0px;

  } 
  .title-span{
    color: rgb(36, 204, 255);
  }  
   .title-span2{
    color: rgb(255, 156, 36);
    /* color: #d9888b; */
  }  
  .subtile{
    
    width: 90%;
    text-align: center;
    font-size: 40px;
    margin: 0 auto;
    padding-left: 60px;
    margin-top: -50px;
  }  


  .title-slider2{
    text-transform: uppercase;
    width: 60%;
    text-align: left;
    font-size: 60px;
    font-weight: bold;
    padding-left: 100px;
    padding-top: 180px;
    color: black;
    font-family: "times new roman", times, roman, serif !important
  } 
  .title-span3{
    color: rgb(36, 204, 255);
  }  
  .subtile2{
    width: 35%;
    text-align: left;
    font-size: 30px;
    margin-top: -50px;
    padding-left: 100px;
    color: black;
    font-weight: bold;
  }
  



  .loader-container {
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100 !important;
    overflow: hidden;
}

.load {
    height: 100%;
    width: 0;
    background-color: rgb(36, 204, 255);
    transition: width 0.2s ease;
}
.button-more button{
  background-color: rgb(23, 177, 224);
  border: none;
  border-radius: 15px;
  font-size: 20px;
  padding: 10px;
  width: 250px;
  color: white;
  height: 60px;
}
.button-more{
  padding-top: 50px;
}
.button-more button:hover{
  cursor: pointer;
}

.microsite-solutions{
  text-align: center;
  margin-top: 50px;
}
.microsite-solutions button{
  background-color: rgba(0, 0, 0, 0.211);
  font-size: 30px;
  border-radius: 10px;
  border: none;
  padding: 20px;
  color: white;
  font-weight: bold;
  width: 40%;
  cursor: pointer;
  border: 6px solid white;
}

.microsite-solutions button:hover{
  /* background-color: #ea9124; */
}


@media only screen and (max-width: 480px) {
  .title-slider{
    font-size: 45px;
    margin-left: -40px;
    margin-top: -250px;
  } 

  .container-text{
    width: 100%;
  }
  .subtile{
    font-size: 30px;
    margin-left: -40px;
    padding-top: 15px;
  }
  .container-slider{
    height: 70vh;
  }
  .microsite-solutions button{
    width: 80%;
    font-size: 28px;
    padding: 10px;
  }
}