.our_services-container{
    width: 100%;
    height: auto;
    position: relative;
    background-image: url("../../img-components/pagina.png");
    background-size: cover;
    background-position-y: 18%;
    margin-top: -1px;
    overflow: hidden;
}

.our-services-grid{
    width: 90%;
    display: grid;
    grid-template-columns: 30% 5% 65%;
    grid-template-areas: "a b c";
    margin: 0 auto;
    padding-top: 170px;
}
.ourservg1{
    grid-area: a;
}
.ourservg2{
    grid-area: c;
}

.services-title{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 0px;
}

.services-title h2{
    font-size: 38px;
    font-weight: bold;
    width: 100%;
    text-align: justify;
}
.services-title h2 span{
    /* color: #466175; */
    color: #00b5f8;
}

.services-title p{
    width: 100%;
    font-size: 22px;
    text-align: justify;
}
.services-title{
    text-align: center;
}
.services-title img{
    width: 90%;
}

.ourservg2 .sec-cont-p p{
    font-size: 22px;
    text-align: justify;
}

.sec1a, .sec1b, .sec1c{
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-areas: "a b";
    grid-gap: 10px;
    padding-bottom: 40px;
}
.sec1a .circle-serv, .sec1b .circle-serv, .sec1c .circle-serv{
    background-color: #00b5f8;
    /* background-color: #466175; */
    color: white;
    font-size: 25px;
    font-weight: bold;
    border-radius: 50%;
    height: 60px;
    text-align: center;
    width: 60px;
}
.sec1a .sec-cont-p p,
.sec1b .sec-cont-p p,
.sec1c .sec-cont-p p{
    padding-top: 0px;
    margin-top: 0px;
    font-size: 22px;
}
.sec1a{
    padding-left: 0px;
}
.sec1b{
    padding-left: 50px;
}
.sec1c{
    padding-left: 100px;
}
.circle-serv p{
    padding-top: 10px;
}

.secc-black-weight-p{
    font-weight: bold;
}

@media only screen and (max-width: 480px) {

    .our-services-grid{
        grid-template-areas: 
        "a a a"
        "c c c";
        padding-top: 0px;
    }
    .our_services-container{
        background: none;   
    }

    .services-title h2{
        font-size: 38px;
        text-align: center;
    }
    .services-title p{
        width: 100%;
        font-size: 22px;
        text-align: justify;
    }

    .sec1a, .sec1b, .sec1c{
        padding-bottom: 10px;
        grid-template-columns: 0% 100%;
        grid-template-areas: "a b";
    }
    .sec1a .circle-serv, .sec1b .circle-serv, .sec1c .circle-serv{
        display: none;
    }
    .sec1a{
        padding-left: 0px;
    }
    .sec1b{
        padding-left: 0px;
    }
    .sec1c{
        padding-left: 0px;
    }

}
