.solutions_container{
    height: auto;
    width: 100%;
    padding-bottom: 50px;
}
.solutions_desc{
    height: 100vh;
}
.solutions_clients{
    height: auto;
    overflow: hidden;
}
.solutions_clients h2{
    font-size: 37px;
    text-align: center;
    font-weight: bold;
    
}
.solutions_clients span{
    color:  rgb(34, 148, 210);
}
.menu-seccion{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-areas: "a b c d";
}
.menu-seccion .items{
    font-size: 18px;
    text-align: left;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    align-items: center;
}
.menu-seccion .items img{
    width: 70px;
    height: 70px;
    padding-left: 20px;
}
.menu-seccion .items p{
    padding-left: 20px;
    font-weight: bold;
}
.itemselect{
    background-color: rgb(34, 148, 210);
    color: white;
}
.itemselect img{
   filter: invert(100%);
}
.items:hover{
    cursor: pointer;
}
.desc-items{
    width: 80%;
    margin: 0 auto;
}
.desc-items p{
    font-size: 20px;
    padding: 20px;
    margin-top: 0px;
}
.desc-serv-items{
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "a b";
    width: 100%;
    background-color: rgb(225, 230, 234);
    padding-top: 15px;
    padding-bottom: 20px;
}
.desc-serv-items p{
    text-align: justify;
    grid-area: a;
}
.desc-serv-items img{
    width: 60%;
    padding-left: 50px;
    grid-area: b;
}
.im3{
    padding-top: 20px;
}
.im4{
    width: 40% !important;
}

.itemss1{grid-area: a;}
.itemss2{grid-area: b;}
.itemss3{grid-area: c;}
.itemss4{grid-area: d;}

@media only screen and (max-width: 480px) {
    .menu-seccion{
        grid-template-areas: 
            "a b c d";
    }
    .menu-seccion .items p{
        display: none;
    }
    .menu-seccion .items img{
        width: 100%;
        padding-left: 0px;
    }
    .desc-serv-items{
        grid-template-areas: "a a";
    }
    .desc-serv-items img{
        display: none;
    }

}


/* seccione de clientes que confian en nosotros */
.clientConf{
    font-size: 40px;
    text-align: center;
    color: white;
    padding-top: 20px;
    text-transform: uppercase;
    z-index: 200;
    position: relative;
}
.about-clients-confi{
    background-image: url("../../img-components/fondo2.png");
     /* background-image: url("../../img-components/fondo-serv.png"); */
    background-size: cover;
    height: auto;
    padding-bottom: 50px;
    background-position-y: 100%;
    position: relative;
}
.capa-dark-ser{
    background-color: rgba(0, 0, 0, 0.404);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wrapper {
    width: 80%; 
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.625);
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    z-index: 200;
}
.carrusel{
    display: flex;
    animation: scroll 15s linear infinite;
   -webkit-animation: scroll 15s linear infinite;
   width: calc(200px * 7);
}

.imgGenaral{
    width: 100px;
} 
.logoboll{
    width: 200px;
    padding-top: 50px;
}
.logoiaia{
    width: 200px;
}
.logofc{
    width: 200px;
}
.logo-curiel{
    height: 90%;
    width: 180px;
}
.logo-kele{
    height: 90%;
}

.item {
    flex: 0 0 auto;
    margin-right: 100px; 
    white-space: nowrap; 
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
       -webkit-transform: translateX(calc(-250px*7));
       transform: translateX(calc(-250px * 7));
    }
}





@media only screen and (max-width: 480px) {
    
}