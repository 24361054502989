.custom-container{
    background-size: cover;
    height: auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
    background-image: url("../../img-components/fondo-serv.png");
    background-position-y: 20%;
}
.container-custom-text{
    position: relative;
    z-index: 10;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 50px;
}
.container-custom-text h2{
    font-size: 60px;
    color: white;
    font-weight: bold;
    text-align: center;
}
.container-custom-text p{
    font-size: 30px;
    color: white;
    margin: 0 auto;
    text-align: justify;
    padding-bottom: 50px;
}
.button-cl-b{
    text-align: center;
}
.button-cl-b button{
    font-size: 22px;
    border-radius: 10px;
    border: none;
    padding: 10px;
    width: 270px;
    height: 60px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    font-weight: bold;
}
.button-cl-b button:hover{
    cursor: pointer;
    background-color: white;
    color: black;
}


.iconos-solutions{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-areas: "a b c d";
    width: 80%;
    margin: 0 auto;
}
.iconos-solutions{
    text-align: center;
}
.iconos-solutions p{
    width: 100%;
    text-align: center;
    font-size: 30px;
}
.iconos-solutions img{
    width: 150px;
}
.icon1{grid-area: a;}
.icon2{grid-area: b;}
.icon3{grid-area: c;}
.icon4{grid-area: d;}




@media only screen and (max-width: 480px) {

    .container-custom-text h2{
        font-size: 50px;
    }
    .container-custom-text p{
        font-size: 25px;
    }
    .iconos-solutions{
        grid-template-areas: 
            "a a b b"
            "c c d d";
        width: 100%;
    }
    .iconos-solutions img{
        width: 80%;
    }
    .iconos-solutions p{
        font-weight: bold;
        font-size: 25px;
    }
}

