.footer-container{
    height: auto;
    background-image: url("../../img-components/slider/slider1.png");
    width: 100%;
    overflow: hidden;
}

.footer-seccions{
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas: "a b c d e";
    margin: 0 auto;
    padding-top: 20px;
}
.footer-seccions .logo img{
    width: 150px;
}
.footer-seccions .logo{
    text-align: center;
    padding-top: 20px;
}
.footer-seccions h2{
    color: white;
}
.secc1, .secc2, .secc3, .secc4{
    width: 100%;
    height: 100%;
    border-right: 1px solid gray;
}
.secc1 h2, .secc2 h2, .secc3 h2, .secc4 h2{
    font-size: 25px;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
}
.line-2{
    background-color: rgb(55, 193, 235);
    height: 2px;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.secc1 p, .secc2 p, .secc3 p, .secc4 p{
    font-size: 20px;
    color: white;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
.redes{
    display: flex;
    padding-left: 25px;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
}
.redes svg{
    color: white;
    font-size: 30px;
}
.redes p{
    margin-top: 0px;
}
.redes:hover{
    border: 1px solid white;
    cursor: pointer;
}
.copy{
    height: 60px;
}
.copy p{
    color: white;
    padding-left: 20px;
    padding-top: 20px;
}
.privacidad{
    padding-left: 20px;
}
.privacidad:hover{
    color: rgb(55, 193, 235);
    cursor: pointer;   
}
.modal-text{
    width: 70%;
    min-width: 70% !important;
    max-width: 70% !important;
    text-align: center;
    padding-top: 30px;
}

.secc2 p:hover{
    cursor: pointer;
    text-decoration: underline;
}
.secc3 p:hover{
    cursor: pointer;
    text-decoration: underline;
}


@media only screen and (max-width: 480px) {
    .modal-text{
        width: 90%;
        min-width: 90% !important;
        max-width: 90% !important;
        text-align: center;
        padding-top: 30px;
    }
}

