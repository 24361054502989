.navbar-container-aws{
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 200 !important;
}
.grid-menu-aws{
    display: grid;
    width: 100%;
    grid-template-columns: 10% 90%;
    grid-template-rows: "a b";
}
.icono-aws{
    padding: 10px;
}
.icono-aws img{
    padding-left: 20px !important;
    width: 100px;
}
.menu-items-aws {
    display: flex;
    justify-content: end;
    margin-right: 50px;
}

  .ul-items-aws {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 10px;
  }

  .ul-items-aws li {
    margin: 10px;
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    margin-left: 50px;
  }
  .ul-items-aws li a{
    text-decoration: none;
    font-size: 20px;
    color: white;
    margin: 0 auto;
}
.ul-items-aws li a::after {
    content: "";
    width: 0;
    height: 2px;
    background-color: white; /* Color del subrayado */
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }
  .ul-items-aws li a:hover::after {
    width: 100%;
  }

  /* imagen icono */
  .icon-img {
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    transform: translatex(0px) translateY(20px);
  }
  .ul-items li:hover .icon-img {
    transform: translateX(0px) translateY(0px);
    opacity: 1;
    filter: invert(1);
  }

  /* Estilo para el menú desplegable de idioma */
.language-dropdown-aws {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Estilo para las opciones de idioma */
.language-options-aws {
  display: none;
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: auto; /* El ancho se ajustará automáticamente según el contenido */
  overflow: hidden; /* Añade un desbordamiento oculto si el contenido es más ancho */
  background-color: rgba(0, 0, 0, 0.023);
}

.language-dropdown-aws {
  font-size: 20px !important;
}

.language-dropdown-aws:hover .language-options {
  display: block;
}

.language-options-aws li {
  padding: 8px 12px;
}


  
.language-dropdown-aws{
  font-size: 22px;
  color: white;
  width: 100%;
  text-align: left;
}

.language-dropdown-aws:hover .language-options-aws {
  display: block;

}
  /* Estilo para las opciones de idioma */
  .language-options-aws {
    display: none;
    position: absolute;
    list-style-type: none;
    width: auto; /* El ancho se ajustará automáticamente según el contenido */
    background-color: rgba(0, 0, 0, 0.092);
  }
  .language-dropdown-aws li{
    margin-left: 0px;
  }

  .menu-cel{
    display: none !important;
  }
  .menu-flotante-cel{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-image: url("../../../img-components/cloud-slide.avif");
  }

  .menu-flotante-items{
    width: 80%;
    margin: 0 auto;
    margin-top: 120px;
  }
  .menu-flotante-items li{
    color: white;
    font-size: 30px;
    list-style: none;
    text-align: center;
    height: 100px !important;
    padding-top: 30px;
  }
  .menu-flotante-items li a{
    text-decoration: none;
    color: white;
  }

  .menu-flotante-items li:hover{
    cursor: pointer;
    border: 1px solid white;
    border-radius: 10px;
  }

  
@media only screen and (max-width: 720px) {

  .menu-cel{
    display: block !important;
  }

  .menu-items-aws {
    display: none;
  }
  .menu-cel{
    display: block;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px !important;
    margin-right: 20px;
    margin-top: 20px;
  }
  .menu-cel:hover{
    cursor: pointer;
  }
}

@media only screen and (max-width: 480px) {
  .menu-cel{
    display: block !important;
  }
}

